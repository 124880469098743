import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkTasksFetching, getTasksData } from "selectors/tasks";
import { getAllUsersData } from "selectors/organizations";
import { getGlobalStats } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import Filters from "./lib/Filters";
import ListItemDetails from "./lib/ListItemDetails";
import NoDataContent from "nlib/common/NoDataContent";
import PageActionButtons from "mlib/common/PageActionButtons";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo } from "react";
import TaskListItem from "./lib/TaskListItem";
import TasksActions from "actions/TasksActions";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useEnvVars from "hooks/useEnvVars";

const TASK_TARGET_USERS = {
  ACCOUNTANT: "accountant",
  CLIENT: "client"
};

const TasksPage = () => {
  const dispatch = useDispatch();

  const [envVars, setEnvVars] = useEnvVars();

  const { editTask, fromDate, toDate } = envVars;

  const { uiTexts, messages } = useSelector(getTextsData);

  const [editTaskId] = editTask ? editTask.split(".") : [];

  const allTasksData = useSelector(getTasksData);

  const tasksFetching = useSelector(checkTasksFetching);

  const globalStats = useSelector(getGlobalStats);

  const allUsersData = useSelector(getAllUsersData);

  const editTaskData = Utils.arrayFindById(allTasksData, editTaskId);

  const filteredTasksData = useMemo(() => {
    if (!envVars.text) return allTasksData;

    const searchString = envVars.text.trim().toLowerCase();

    return allTasksData.filter(({ preview, title, text, targetUser, createdBy }) =>
      [preview, title, text, targetUser?.fullName, targetUser?.email, createdBy?.fullName, createdBy?.email]
        .some((item) => (item || "").toLowerCase().includes(searchString)));
  }, [envVars.text, allTasksData]);

  const tasksFilteredByUser = useMemo(() => {
    if (!envVars.targetUser) return filteredTasksData;

    switch (envVars.targetUser) {
      case TASK_TARGET_USERS.ACCOUNTANT:
        return filteredTasksData.filter(({ targetUser }) => !targetUser || UserRoles.checkIsAccountant(targetUser?.role));
      case TASK_TARGET_USERS.CLIENT:
        return filteredTasksData.filter(({ targetUser }) => UserRoles.checkIsBusiness(targetUser?.role));
      default:
        return filteredTasksData.filter(({ targetUser }) => (targetUser?.id === envVars.targetUser)
          || (!targetUser && !UserRoles.checkIsBusiness(Utils.arrayFindById(allUsersData, envVars.targetUser)?.role)));
    }
  }, [envVars.targetUser, filteredTasksData, allUsersData]);

  const filteredTasksByPeriod = useMemo(() => {
    if (!fromDate && !toDate) return tasksFilteredByUser;

    return tasksFilteredByUser.filter(({ startDate }) => {
      const momentDate = moment.utc(startDate);

      return (!fromDate || momentDate.isSameOrAfter(moment.utc(fromDate).startOf("day")))
        && (!toDate || momentDate.isSameOrBefore(moment.utc(toDate).endOf("day")));
    });
  }, [fromDate, tasksFilteredByUser, toDate]);

  const handleItemDetailClose = useCallback(() => {
    setEnvVars({ editTask: null });
  }, [setEnvVars]);

  const handleTaskSelect = useCallback((itemId) => {
    setEnvVars({ editTask: itemId });
  }, [setEnvVars]);

  const handleAddTaskButtonClick = useCallback(() => {
    setEnvVars({ editTask: Constants.NEW_ENTITY_ID });
  }, [setEnvVars]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(TasksActions.fetchTasksList());
  }, [dispatch]);

  if (!globalStats.transactions) return <Preloader />;

  return (
    <>
      <Filters />
      <div className={classNames(Css.tasksPage, CommonCss.mobileContainer)}>
        <div className={Css.content}>
          {filteredTasksByPeriod.length
            ? filteredTasksByPeriod.map((task) => (
              <TaskListItem
                key={task.id}
                className={Css.item}
                taskData={task}
                onSelect={handleTaskSelect} />
            ))
            : (tasksFetching ? <Preloader /> : (
              <NoDataContent
                title={!!allTasksData.length && uiTexts.noTasks}
                description={!allTasksData.length && messages.tasksBlockPlaceholder} />
            ))}
          {editTaskId && (
            <ListItemDetails
              taskId={editTaskId}
              data={editTaskData}
              onClose={handleItemDetailClose} />
          )}
        </div>
        <PageActionButtons>
          <Button
            primary
            icon={Icons.Plus}
            onClick={handleAddTaskButtonClick}>
            {uiTexts.addNewTask}
          </Button>
        </PageActionButtons>
      </div>
    </>
  );
};

export default React.memo(TasksPage);
